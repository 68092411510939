import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import { Layout } from "../components/Layout";
import { Container, Heading, TagLine } from "../components/Page";
import { PageSeo } from "../components/Seo";

const NotFoundPage = () => {
  return (
    <>
      <PageSeo title="Not Found" tabTitleSegments={["Not Found"]} />
      <Layout>
        <Container>
          <Heading>Page Not Found</Heading>
          <TagLine>Sorry we couldn't find the page you're looking for</TagLine>
          <NotFoundHomeLinkContainer>
            <NotFoundHomeLink to="/">Go to home page</NotFoundHomeLink>
          </NotFoundHomeLinkContainer>
        </Container>
      </Layout>
    </>
  );
};

const NotFoundHomeLinkContainer = ({ children }) => {
  return (
    <div className="mt-6 mb-12 text-center">
      {children}
    </div>
  );
};

const NotFoundHomeLink = ({ as: Component = Link, className, children, ...props }) => {
  return (
    <Component className={classNames("text-lg underline", className)} {...props}>
      {children}
    </Component>
  );
};

export default NotFoundPage;
